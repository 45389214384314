body {
  margin: 0;
  padding: 0;
  /*font-family: sans-serif;*/
  line-height: 26px !important;
  font-family: "lato", "lato-black", "lato-bold"; /*'Graphik Web','arial'; */
  font-size: 16px;
  /*font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",*/
  /*"Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",*/
  /*sans-serif;*/
  /*-webkit-font-smoothing: antialiased;*/
  /*-moz-osx-font-smoothing: grayscale;*/
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

h3,
h4 {
  margin-bottom: 0;
  margin-top: 5px;
  color: #4a4a4a;
  margin-top: 0;
  margin-bottom: 0;
}

h4 {
  margin: 0;
}

#double-header .ag-row:nth-of-type(2),
#monthly-capacity-table .ag-row:nth-of-type(1) {
  background: #00374d !important;
}
